/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

body {
    --primary: #ffd100;
    --secondary: #021442;
    --tertiary: #e31e3a;
    --complement: #00ade8;
    --secondaryOpacity: #a9e2f8;
    --other: #002157;
    --text-dark: #595a5a;
    --text-semiDark: #848686;
    --text-semiLight: #d7d8d8;
    --text-light: #ebeced;

    font-family: 'Roboto', sans-serif;
}

button:focus {
    outline: none!important;
}